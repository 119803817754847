import React, { createContext, useReducer, useContext } from 'react';
import { saveCart } from '../components/services/api';

const CartContext = createContext();

const initialState = {
    items: [], 
};

const cartReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CART':
            return { ...state, items: action.payload };
        case 'ADD_ITEM':
            const existingItem = state.items.find(
                    (item) => 
                        item.id === action.payload.id && 
                        item.product.product_type === action.payload.product.product_type
                );            
                if (existingItem) 
                {
                    // const updatedItems = state.items.map((item) =>
                    //     item.id === action.payload.id
                    //     ? {
                    //         ...item,
                    //         quantity: Number(item.quantity) + 1, 
                    //         }
                    //     : item
                    // );
                    const updatedItems = state.items.map((item) => {
                        if (item.id === action.payload.id) {
                            if (item.product.product_type === 'gold') {
                                // Update quantity and price for gold
                                const newQuantity = item.quantity + action.payload.quantity;
                                const newPrice = newQuantity * Number(item.product.rawProduct.rate);
                              
                                return {
                                    ...item,
                                    quantity: newQuantity,
                                    product: {
                                        ...item.product,
                                        price: newPrice, // Update product.price
                                    },
                                };
                            } else {
                                // Normal flow for non-gold items
                                return { ...item, quantity: item.quantity + 1 };
                            }
                        }
                        return item;
                    });
                    saveCart(updatedItems);
                    return { ...state, items: updatedItems };
                } else {
                    const initialQuantity =
                    action.payload.product.product_type === 'gold'
                        ? action.payload.quantity // Use mbuyValue for gold
                        : Number(action.payload.product.minimum_buy_limit) || 1; // Normal flow for others
                    const newCart = [
                        ...state.items,
                        { ...action.payload, quantity: initialQuantity },
                    ];
                    saveCart(newCart);
                    return { ...state, items: newCart };
                }
        case 'REMOVE_ITEM':
            const filteredCart = state.items.filter((item) => item.id !== action.payload);
            saveCart(filteredCart); 
            return { ...state, items: filteredCart };
        case "RESET_CART":
            return { ...state, items: [] };
        case 'UPDATE_ITEM':
            {
                const updatedItems = state.items.map((item) => {
                    if (item.id === action.payload.id) {
                        if (item.product.product_type === 'gold') {
                            // Dynamically calculate price for gold items
                            const newPrice = action.payload.quantity * item.product.rawProduct.rate;
                            return {
                                ...item,
                                ...action.payload,
                                product: {
                                    ...item.product,
                                    price: newPrice, // Update product.price for gold
                                },
                                price: newPrice, // Update the item-level price
                            };
                        } else {
                            // Normal flow for other items
                            return {
                                ...item,
                                ...action.payload,
                            };
                        }
                    }
                    return item;
                });
                saveCart(updatedItems);
                return { ...state, items: updatedItems };
            }
        default:
            return state;
    }
};

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);
    return (
        <CartContext.Provider value={{ state, dispatch }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
