import React from "react";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from '../../website-assets/images/logo.svg'
import visa from "../../website-assets/images/visa.svg";
import goldicon from '../../website-assets/images/goldicon.png';
import bitcoin from "../../website-assets/images/bitcoin.png";
import { coinTransaction } from "../services/api";
import { Helmet } from 'react-helmet-async';
import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getPaymentIntent } from "../services/api";
import StripeCheckout from './StripeCheckout';
import { useCart } from "../cartContext";

const stripePromise = loadStripe('pk_live_51QICLrAYSKOIxvmw24DGtQtHiTuVqv1Suh1rK8VuNJmPMbxEDoITkr3gFbGqwUtjHEYMMMg7a3aBfl7rGZxpGrx200T8DG1mKX');
const Checkout=()=>{
	const [element,setElement] = useState({});
	const [accountcheck, setAccountCheck]= useState(false);
	const [processingFee, setProcessingfee] = useState(0);
	const [accountemail, setAccountEmail] = useState("");
	const [runescapename, setRunescapename] = useState();
	const [runescapeemail, setRunescapeemail] = useState(sessionStorage.getItem('user_email'));
	const [selectedPayment, setSelectedPayment] = useState('');
	const [totalSum, setTotalSum] = useState(0);
	const [finalSum, setFinalsum] = useState(0);
	const [type, setType] =  useState();
	const [isloading, setIsLoading] = useState(false);
	const [clientSecret, setClientSecret] = useState("");
	const { state,dispatch } = useCart();
	const canonicalUrl = `${window.location.href}`;
	const navigate = useNavigate();
	useEffect(() => {
		state.items.forEach((item) => { 
			if (item.product.product_type === "account") {
				setAccountCheck(true);
			}
		});
		setSelectedPayment(sessionStorage.getItem('paymentMethod'));
		
	}, []);
	const normalizeItemProduct = (product) => {
    
      return {
        id: product.id,
        name: product.title || product.name,
        price: product.cost || product.price,
        type: product.type,
        image: product.image,
        product_type: 'product',
        minimum_buy_limit: product.minimum_buy_limit || 1,
        rawProduct: product,
      };
  };  
	const normalizeAccountProduct = (product) => {
		const cropImagePath = (path) => {
		if (!path) return ""; 
		return path.replace("/uploads", ""); 
		};

		return {
			id: product.id,
			name: product.title || product.name,
			price: product.cost || product.price,
			image: product.images ? cropImagePath(product.images[0]?.account_image) : cropImagePath(product.image),
			type: product.type,
			product_type: 'account',
			rawProduct: product,
		};
	};  
	const normalizeGoldProduct = (product) => {
		return {
			id: Math.floor(10000 + Math.random() * 90000),
			price:product.usdbuyValue,
			mbuyValue: product.mbuyValue,
			runescapename: product.runescapename,
			email: product.email,
			product_type: 'gold',
			rawProduct: product,
		};
	};  
	useEffect(() => { 
		if (sessionStorage.getItem('type') === '1') {
			const product = normalizeAccountProduct(JSON.parse(sessionStorage.getItem('selectedAccount')));
			setType(sessionStorage.getItem('type'));
			dispatch({
				type: "ADD_ITEM",
				payload: { id: product.id, product, quantity: 1 },
			});
		} else if (sessionStorage.getItem('type') === '0') { 
			const product = normalizeItemProduct(JSON.parse(sessionStorage.getItem('selectedAccount')));
				const initialQuantity = product.minimum_buy_limit || 1;
				setType(sessionStorage.getItem('type'));
				dispatch({
					type: "ADD_ITEM",
					payload: { id: product.id, product, quantity: initialQuantity },
			});
		}else if (sessionStorage.getItem('type') === '2'){
			const product = normalizeGoldProduct(JSON.parse(sessionStorage.getItem('selectedAccount')));
			setType(sessionStorage.getItem('type'));
			setRunescapename(product.runescapename);
			dispatch({
				type: "ADD_ITEM",
				payload: { id: product.id, product, quantity: Number(product.mbuyValue)},
			});
		}
		sessionStorage.removeItem('selectedAccount')
		sessionStorage.removeItem('type')
	},[]);
	useEffect(()=>{
		
	},[state]);
	useEffect(() => {
		
		// Calculate total sum whenever cart items change
		const total = state.items.reduce((sum, item) => {
			if (item.product.product_type === 'gold') {
				// For gold, add only the item's price
				return sum + Number(item.product.price);
			} else {
				// For other items, use price * quantity
				return sum + item.product.price * item.quantity;
			}
		}, 0);
		if(sessionStorage.getItem("paymentMethod") === "visa"){
			console.log("here");
			console.log(sessionStorage.getItem("paymentMethod") === "visa")
			console.log(total);
			const fee = calculateProcessingFee(total);
			console.log("fee"+fee);
			setProcessingfee(fee);
			console.log(total+fee);
			setFinalsum(total+fee);
		}
		else{
			setProcessingfee(0);
			setFinalsum(total)
		}
		setTotalSum(total);

		
	 // Update the total sum state
	}, [state.items, navigate]);

	const calculateProcessingFee = (total) => {
		
		return total > 20 ? total * 0.08 : total * 0.10;
		
	};

	const handlePaymentChange = (event) => {
		setSelectedPayment(event.target.value);
		sessionStorage.setItem("paymentMethod",event.target.value);
		if(event.target.value === "visa"){
			const fee = calculateProcessingFee(totalSum);
			setProcessingfee(fee);
			setFinalsum(totalSum+fee);
		}
		else{
			setProcessingfee(0);
			setFinalsum(totalSum);
		}
		
	};
	
	  const handleChangerunescapename=(e)=>{
		setRunescapename(e.target.value)
	  }
	  const handleChangeRunescapeEmail=(e)=>{
		setAccountEmail(e.target.value)
	  }
	  const handleChangeEmail=(e)=>{
		setRunescapeemail(e.target.value)
	  }
	  

		const [errors, setErrors] = useState({});
		
		  const validate = () => {
			let errors = {};
			if(!accountcheck)
			{
				return true;
			}
		
			if(accountcheck && !accountemail){
				errors.runescapeemail= 'Email is required';
			}
			
			setErrors(errors);
		
			return Object.keys(errors).length === 0;
		  };

		  const handleSubmit = async (e) => {
			sessionStorage.setItem("orderMessageSent", "true");
			  
			e.preventDefault();
			if (validate()) {
				const token = sessionStorage.getItem('token');
				const id = element.id;
				const userid = sessionStorage.getItem("user_id");
				const item_type = sessionStorage.getItem("item_type");
				const formData = new FormData();
				formData.append("runescapeemail", runescapeemail);
				formData.append("runescapename", sessionStorage.getItem('user_name'));
				formData.append("userid", userid);
				formData.append("price", finalSum);
				formData.append("accountemail", accountemail);

				const cartItems = state.items.map((item, index) => ({
					id: item.product.id,
					name: item.product.name,
					quantity: item.quantity,
					price: item.product.price,
					data: item.product.rawProduct,
					type: item.product.product_type,
				}));

				cartItems.forEach((item, index) => {
					formData.append(`items[${index}][id]`, item.id);
					formData.append(`items[${index}][name]`, item.name);
					formData.append(`items[${index}][quantity]`, item.quantity);
					formData.append(`items[${index}][price]`, item.price);
					formData.append(`items[${index}][rawProduct]`, JSON.stringify(item.data));
					formData.append(`items[${index}][type]`, item.type);
				});
				const plainObject = Object.fromEntries(formData.entries());

				sessionStorage.setItem('formData',JSON.stringify(plainObject));

				setIsLoading(true);
				if(selectedPayment === "bitcoin")
				{
					const response =await coinTransaction(token,formData);
					sessionStorage.removeItem("selectedAccount");
					sessionStorage.removeItem("type");
					if(response){
						sessionStorage.setItem("message", JSON.stringify(response.data.order));
						window.location.href = response.data.url;	
					}			
				}else if(selectedPayment === "visa")
				{
					const response = await getPaymentIntent(Number(finalSum));
					if(response){
						setClientSecret(response.data);
						setIsLoading(false);
					}
				}
			}
		  };

		 
	return (
		<>
		<Helmet>
			<title>Checkout</title>
			<link rel="canonical" href={`${canonicalUrl}`} />
      	</Helmet>
		<div className="login-register mh-100">
			<em class="over position-absolute bg-black opacity-75 bottom-0 top-0 end-0 start-0"></em>
			<div class="container vh-100 position-relative z-1">
			  <div class="row justify-content-center">
				<div class="col-xl-10 col-lg-10 col-md-12">
				<section class="bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
					<div class="row">
					<div class='col-lg-6'>
						{isloading?(<>								
						<div className="text-center order-loading-div ">
							<div className="spinner-border text-warning" role="status"></div>
							<br></br>
							<div className="loader-text mt-4">
							<span className="text-warning mt-4">Processing Order</span>
							</div>
						</div>
						</>):clientSecret?(<>
							<Elements stripe={stripePromise} options={{ clientSecret }}>
								<StripeCheckout clientSecret = {clientSecret} setIsLoader={setIsLoading} /> 
							</Elements>
						</>):(<>
							<div class="my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Finalize Your Order</h1></div>
							<form onSubmit={handleSubmit}>
							{errors.apiError && <span className="text-danger">{errors.apiError}</span>}
							{errors.runescapename && <span className="text-danger">{errors.runescapename}</span>}
									
											{type === '2'? <div class="input-group mb-4 mt-2 bg-light-ben">
													<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="runescapename" placeholder="Enter Your Runescape Name*" value={runescapename} onChange={handleChangerunescapename} />
												</div>:<></> }
									
										{accountcheck? <><span className="text-warning">Account login details will be send to this email</span><br></br><br></br>
											{errors.runescapeemail && <span className="text-danger mt-3">{errors.runescapeemail}</span>}
										<div class="input-group mb-4 mt-2 bg-light-ben">
											<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="runescapeemail" placeholder="Enter Your Email Address*" value={accountemail} onChange={handleChangeRunescapeEmail} />
										</div></>:<>
										{/* <div class="input-group mb-4 mt-2 bg-light-ben">
											<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="runescapeemail" placeholder="Enter Your Email Address*" value={runescapeemail} onChange={handleChangeEmail} />
										</div> */}
										</>}
										
									<h3 class="text-light-ben py-4">
									<strong class="fw-bold">Payment Method:</strong>
									</h3>
									<div class="row">
										<div class="col">
											<label class="payments transition d-block text-center">
												<input
													type="radio"
													name="payment"
													value="visa"
													class="d-none"
													checked={selectedPayment === 'visa'}
													onChange={handlePaymentChange}
												/>
												<img
													width={100}
													src={visa}
													alt="Visa Master Card"
													class="img-fluid "
												/>
											</label>
										</div>
										<div class="col">
											<label class="payments transition d-block text-center">
												<input
													type="radio"
													name="payment"
													value="bitcoin"
													class="d-none"
													checked={selectedPayment === 'bitcoin'}
													onChange={handlePaymentChange}
												/>
												<img
													src={bitcoin}
													alt="Bitcoin"
													class="img-fluid bitcoin"
													width="100"
												/>
											</label>
										</div>
										{/* <div class="col">
											<label class="payments transition d-block text-center">
												<input
													type="radio"
													name="payment"
													value="binance"
													class="d-none"
													checked={selectedPayment === 'binance'}
													onChange={handlePaymentChange}
												/>
												<img
													src={binance}
													alt="Binance"
													class="img-fluid"
												/>
											</label>
										</div> */}
									</div>
									
									<div className="invoice-products mt-3">
												<span class="heading">Order Summary</span>
												<table className="mt-2">
													
													<tr>
														<td className="ps-0">Subtotal:</td>
														<td>${totalSum.toFixed(2)}</td>
													</tr>
													{selectedPayment === "visa" && (
														<tr>
															<td className="ps-0">Processing Fee:</td>
															<td>${processingFee.toFixed(1)}</td>
														</tr>
													)}
													<tr>
														<td className="ps-0">Total:</td>
														<td>${finalSum.toFixed(1)}</td>
													</tr>
												</table>
									</div>
							
							<div class="mb-4 mt-4">
							<button name="btn" class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase"  disabled={!selectedPayment  || state.items.length === 0}>Proceed Checkout</button>
							</div>
							</form>
						</>)}
						</div>
							{state.items.length > 0 ? (
							<div className="col-lg-6 pt-4">
								<strong className="h3 d-block pb-4 fw-bold text-white">CART ITEMS</strong>
								<div className="mt-4" style={{ maxHeight: '500px', overflowY: 'auto' }}>
								{state.items.map((item) => (
									<div key={item.id} className="items-box p-3 position-relative mb-4">
										{item.product.product_type === 'product'?
											<div className="d-flex gap-3">
												{/* Product Image */}
												<div className="img-holder position-relative overflow-hidden rounded-1">
												<img
													src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.product.image}`}
													alt={item.product.name}
													className="transition account-image rounded-2 object-fit-cover"
													width="120"
													height="100%"
												/>
												</div>
												{/* Product Details */}
												<div className="details-account">
												<strong className="d-block text-primary-ben lh-150">
													{item.product.name}
												</strong>
												<div className="d-flex gap-2">
													<div className="d-flex gap-1 my-3 fs-12">
													<span className="text-white"></span>
													</div>
													<div className="d-flex gap-1 my-3 fs-12">
													<i
														className="fa fa-cube text-primary-ben fs-11"
														title="Quantity"
													></i>{" "}
													<span className="text-white">Quantity: {item.quantity}</span>
													</div>
													<div className="d-flex gap-1 my-3 fs-12">
													<i
														className="fa fa-cube text-primary-ben fs-11"
														title="Quantity"
													></i>{" "}
													<span className="text-white ">${(item.product.price * item.quantity).toFixed(2)}													</span>
													</div>

												</div>
													<div className="d-flex align-items-center gap-2">
														{/* Quantity Controls */}
														<button
														className="btn btn-primary-ben btn-sm"
														onClick={() =>
															dispatch({
															type: "ADD_ITEM",
															payload: { id: item.id, product: item.product, quantity: 1 },
															})
														}
														>
														+
														</button>
														{/* <span className="text-light px-2">{item.quantity}</span> */}
														 <input
															type="number"
															className="form-control w-10 text-center no-spinner"
															value={item.quantity}
															min={item.product.rawProduct?.minimum_buy_limit || 1}
															onChange={(e) => {
															const newQuantity = parseInt(e.target.value, 10) || 0;
															if (newQuantity >= 1) {
																dispatch({
																type: "UPDATE_ITEM",
																payload: { ...item, quantity: newQuantity },
																});
															}
															}}
															onBlur={(e) => {
															const newQuantity = parseInt(e.target.value, 10) || 1;
															if (newQuantity < item.product.rawProduct?.minimum_buy_limit) {
																dispatch({
																type: "UPDATE_ITEM",
																payload: {
																	...item,
																	quantity: item.product.rawProduct?.minimum_buy_limit || 1,
																},
																});
															}
															}}
														/>


														<button
														className="btn btn-primary-ben btn-sm"
														onClick={() => {
															const minLimit = item.product.rawProduct?.minimum_buy_limit || 1;
															if (
																item.product.product_type === "product" &&
																item.quantity <= minLimit
																) {
																return;
																}
															if (item.quantity > 1) {
															dispatch({
																type: "UPDATE_ITEM",
																payload: { ...item, quantity: item.quantity - 1 },
															});
															} else {
															dispatch({ type: "REMOVE_ITEM", payload: item.id });
															}
														}}
														>
														-
														</button>
														<button
														className="btn btn-danger btn-sm ms-2"
														onClick={() => dispatch({ type: "REMOVE_ITEM", payload: item.id })}
														>
														Remove
														</button>
													</div>
												</div>
											</div>:item.product.product_type === 'account'?
											<><div className="d-flex gap-3">
												{/* Product Image */}
												<div className="img-holder position-relative overflow-hidden rounded-1">
												<img
													src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.product.image}`}
													alt={item.product.name}
													className="transition account-image rounded-2 object-fit-cover"
													width="120"
													height="100%"
												/>
												</div>
												{/* Product Details */}
												<div className="details-account">
												<strong className="d-block text-primary-ben lh-150">
													{item.product.name}
												</strong>
												<div className="d-flex gap-2">
													<div className="d-flex gap-3 fs-12">
														<div class="d-flex gap-1 my-3 fs-12">
															<i
															class="fa fa-user text-primary-ben fs-11"
															title="Account Level"
															></i>{" "}
															<span class="text-white">
																{item.product.rawProduct.category === '1'?'RS3':'OSRS'} 																		
															</span>
														</div>
														<div class="d-flex gap-1 my-3 fs-12">
															<i
															class="fa fa-dollar text-primary-ben fs-11"
															title="Account Level"
															></i>{" "}
															<span class="text-white">
															{item.product.price}
															</span>
														</div>
														<div class="d-flex gap-1 my-3 fs-12">
															<i
															class="fa fa-sign-in text-primary-ben fs-11"
															title="Account Level"
															></i>{" "}
															<span class="text-white">
															{item.product.rawProduct.level}
															</span>
														</div>
														<div class="d-flex gap-1 my-3 fs-12">
															<i
															class="fa-solid fa-list text-primary-ben fs-11"
															title="Price"
															></i>{" "}
															<span class="text-white">{(item.product.offense == '1')?'Offense':'No offence'}</span>
														</div>
														
													</div>
												</div>
												<button
														className="btn btn-danger btn-sm"
														onClick={() => {
															dispatch({ type: "REMOVE_ITEM", payload: item.id })
															const updatedItems = state.items.filter((currentItem) => currentItem.id !== item.id);
															const hasAccountType = updatedItems.some(
																(currentItem) => currentItem.product.product_type === "account"
															);
															setAccountCheck(hasAccountType);
														}
														}
														>
														Remove
														</button>
												</div>
												</div>
											</>:
												<div className="d-flex gap-3">
												{/* Product Image */}
												<div className="img-holder position-relative overflow-hidden rounded-1">
												<img
													src={goldicon}
													alt={item.product.name}
													className="transition account-image rounded-2 object-fit-cover"
													width="120"
													height="100%"
												/>
												</div>
												{/* Product Details */}
												<div className="details-account">
												<strong className="d-block text-primary-ben lh-150">
													{item.product.rawProduct.category === '1'?'RS3':'OSRS'} GOLD
												</strong>
												<div className="d-flex gap-2">
													<div className="d-flex gap-3 fs-12">
														<div class="d-flex gap-1 my-3 fs-12">
															<i
															class="fa fa-dollar text-primary-ben fs-11"
															title="Account Level"
															></i>{" "}
															<span class="text-white">
															Price {item.product.rawProduct.rate} / M
															</span>
														</div>
													</div>
														</div>
														<div className="d-flex align-items-center gap-2">
														{/* Quantity Controls */}
														<button
														className="btn btn-primary-ben btn-sm"
														onClick={() =>
															dispatch({
															type: "ADD_ITEM",
															payload: { id: item.id, product: item.product, quantity: 1 },
															})
														}
														>
														+
														</button>
															{/* <span className="text-light px-2">{item.quantity}</span> */}
															<input
																type="number"
																className="form-control w-10 text-center no-spinner"
																value={item.quantity}
																min={1}
																onChange={(e) => {
																	const newQuantity = parseInt(e.target.value, 10) || 0;
																	if (newQuantity >= 1) {
																	dispatch({
																		type: "UPDATE_ITEM",
																		payload: { ...item, quantity: newQuantity },
																	});
																	}
																}}
																/>
														<button
														className="btn btn-primary-ben btn-sm"
														onClick={() => {
															if (item.quantity > 1) {
															dispatch({
																type: "UPDATE_ITEM",
																payload: { ...item, quantity: item.quantity - 1 },
															});
															} else {
															dispatch({ type: "REMOVE_ITEM", payload: item.id });
															}
														}}
														>
														-
														</button>
														<button
														className="btn btn-danger btn-sm ms-2"
														onClick={() => dispatch({ type: "REMOVE_ITEM", payload: item.id })}
														>
														Remove
														</button>
													</div>
												
												</div>
												</div>}
										
									</div>
								))}
								</div>
							</div>
							) : (
							<div className="col-md-6 ps-md-6 pt-4 text-center">
								<h3 className="text-light-ben">Your cart is empty.</h3>
							</div>
							)}
			</div>
		</section>
				</div>
			  </div>
		    </div>
		</div>
		</>
		
	
	);

}
export default Checkout;