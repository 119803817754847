import React from "react";
import { useState } from "react";
import {PaymentElement} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";
import { createOrder } from "../services/api";
import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';

const StripeCheckout = (props)=>{
	const navigate = useNavigate();
	const [errors ,setErrors] = useState("");
	const [loader, setLoader]= useState(false);
	const elements = useElements();
	const stripe = useStripe(); 

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoader(true)
		const paymentElement = elements.getElement(PaymentElement);
		if (!paymentElement) {
		return;
		}
		const { error, paymentIntent } = await stripe.retrievePaymentIntent(props.clientSecret);
		if (paymentIntent.status !== "succeeded") {
			 setErrors(`Payment failed: ${paymentIntent.status}`);
       		 setLoader(false);
			
      	} else {
			// Payment succeeded, process the order
			const token = sessionStorage.getItem("token");
			const formData = JSON.parse(sessionStorage.getItem("formData"));
			formData.stripe_id = paymentIntent.id;
			const response = await createOrder(token, formData);
			sessionStorage.removeItem("formData");
			sessionStorage.setItem("message", JSON.stringify(response.data.message));
			setLoader(false);
			navigate("/thank-you");
			
      	}
	  };
	  
	return(
		<>
		<span className="text-danger">{errors}</span>
		<PaymentElement />
		{loader?(<div className="text-center mt-4 checkout-loader">
				<div className="spinner-border text-warning" role="status"></div>
					<br></br>
					<div className="loader-text mt-4">
					<span className="text-warning mt-4">Processing Order</span>
				</div>
		</div>):(<></>)
		}
		<button name="btn" onClick={handleSubmit} class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase mt-4">PAY</button>
		</>
	);
}
export default StripeCheckout;