import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
});

export const register = (formData) => API.post("/user-register", formData);
export const login = (formData) => API.post("/user-login", formData);
export const googleLogin = (formData) => API.post("/google-login", formData);


export const osrsgoldRate = () => API.get("/osrs-gold-price");
export const rs3goldRate = () => API.get("/rs3-gold-price");
export const osrsAccounts = () => API.get("/osrs-accounts");

export const blogs = () => API.get("/blogs");
export const getBlog= (slug)=> API.get(`/blogs/${slug}`);

export const osrsProducts = () => API.get("/osrs-products");
export const rs3Accounts = () => API.get("/rs3-accounts");
export const rs3Products = () => API.get("/rs3-products");
export const getAccount = (id)=> API.get(`/get-account/${id}`);
export const getItem = (id)=> API.get(`/get-item/${id}`);
export const currencies = () =>API.get("/currencies");
export const updateosrsgoldrate = (currencyId) =>API.get(`/osrs/gold/rate/update/${currencyId}`);
export const updaters3goldrate = (currencyId) =>API.get(`/rs3/gold/rate/update/${currencyId}`);

export const binancePayment = () => API.get("/binance-create-order");

export const getlevelAccounts =(selectedlevel)=> API.get(`/get-selected-level-accounts/${selectedlevel}`);
export const getSearhedAccounts = (key)=> API.get(`/get-searched-acouunts/${key}`);
export const getSearchedProducts = (key)=> API.get(`/get-searched-products/${key}`);
export const updateOrderPayment = (id,track)=> API.get(`/update-order-status/${id}/${track}`);
export const getPaymentIntent = (price) => API.get(`/payment-intent/${price}`);

export const saveCart = async (cartItems) => {
  const token = sessionStorage.getItem('token'); 
  return API.post(`/cart`, { items: cartItems }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCart = async () => {
  const token = sessionStorage.getItem('token'); // Retrieve user token
  return API.get(`/cart`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createIntercomContact = (token, formData) =>
  API.post(`/create_intercom_contact`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createIntercomConversation = (token, form) =>
  API.post(`/create_intercom_conversation`, form,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });


export const coinTransaction = (token, formData) =>
  API.post(`/create_transaction_coin`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  export const getCheckoutUrl = (token, formData) =>
    API.post(`/create_transaction_visa`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    export const createOrder = (token, formData) =>
      API.post(`/create_order`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

    export const getUpdatedCheckoutUrl = (token, orderId) =>
      API.get(`/update-payment-visa/${orderId}`, {
      
      });
    
    
  export const getChat = (token, userid) =>
    API.get(`/get-chat/${userid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    export const getSupportedcoins = (token) =>
      API.get(`/get-supported-coins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

export const placeOrder = (token, formData) =>
  API.post(`/binance/create-order`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // export const updateProfile = (token, formData) =>
  //   API.post(`/binance/create-order`, formData, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });

export const getUserOrders = (token, userId) =>
  API.post(`/user/orders`, userId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const placeOsrsGoldOrder = (token, formData) =>
  API.post(`/binance/create-gold-order`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateProfile = (token, formData) =>
  API.post(`/update-profile`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  export const userActivity = (token, userId) =>
    API.get(`/pending-order/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

export const sendMessage = (message, userid) =>
  API.post(`/send-message`, {
    user_id: userid,
    message: message,
  });

export const storeChatImage = (formData) =>
  API.post(`/store-chat-image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
