import React from "react";
import { useEffect , useState} from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAccount, getItem } from "../services/api";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const Detail =(props)=>{
	const { id } = useParams();
	const navigate = useNavigate();
	const [isaccount, setIsaccount] = useState(false);
	const [product, setProduct] = useState();
	const [isloading, setIsloading] = useState(false);
	const canonicalUrl = `${window.location.href}`;

	useEffect(()=>{
		getData();
		if(props.component === "ACCOUNT"){
			setIsaccount(true)
		}
	},[id]);

	const handleBackBtn = () => { 
		navigate(-1);
	}

	const handleBuyNow = (account) => {
		sessionStorage.setItem("selectedAccount", JSON.stringify(account));
		sessionStorage.setItem("type", "1");
		const isAuthenticated = sessionStorage.getItem("token");
		if (!isAuthenticated) {
		  sessionStorage.setItem("redirectPath", "/checkout");
		  navigate("/login");
		  return;
		}else{
		  navigate("/checkout")
		}
	  };

	const getData=async()=>{
		setIsloading(true);
		if(props.component === "ACCOUNT"){
			const response = await getAccount(id);
			setProduct(response.data);
		}
		else{
			const response = await getItem(id);
			setProduct(response.data);
		}
		setIsloading(false)
	}
	

	return(
		<>
		 <Helmet>
			<title>{props.component}</title>
			<link rel="canonical" href={`${canonicalUrl}`} />
		</Helmet>
		<div className="background mt-5 ">
			<section class="container dashbaord pt-md-4  ">
			{isaccount?
			<div class="p-4 detail-page ">
				{product?<>
					<h3 class="fs-3 lh-150 fw-bold text-black text-start pt-3">{product.title}</h3>
				<div class="d-flex gap-3">
				<div class="d-flex gap-1 my-3 fs-12">
					<i class="fa fa-trophy text-primary-ben fs-12" title="Account Level"></i> <span>{product.level}</span>
				</div>
				<div class="d-flex gap-1 my-3 fs-12">
					<i class="fa fa-dollar text-primary-ben fs-12" title="Price"></i> <span>{product.cost}</span>
				</div>
				<div class="d-flex gap-1 my-3 fs-12">
				<i
                                  class="fa-solid fa-list text-primary-ben fs-11"
                                  title="Price"
                                ></i>{" "}
                                <span class="text-white">{(product.offense == '1')?'Offense':'No offence'}</span>				
								</div>
				<div class="d-flex gap-1 my-3 fs-12">
				<i
					class="fa fa-clock text-primary-ben fs-11"
					title="Sold"
				></i>{" "}
                <span class="text-white">Instant</span>				
				</div>
				</div>
				<div class="row">
				<div class="col-12 mt-4">
					<div class="post-detials">
					 <div id="detailsSlider" class="carousel slide">
						<div class="carousel-inner" height={200}>
						{product.images.map((image,index)=>
						<div class={`carousel-item ${index === 0 ? 'active' : ''}`} >
							<img src={`${process.env.REACT_APP_API_BASE_URL}${image.account_image}`} alt="Post Details"  class="product-image d-block  h-40" />
						</div>)}
						</div>
						<button class="carousel-control-prev" type="button" data-bs-target="#detailsSlider" data-bs-slide="prev"><i class="fa fa-angle-left fa-2x"></i></button>
						<button class="carousel-control-next" type="button" data-bs-target="#detailsSlider" data-bs-slide="next"><i class="fa fa-angle-right fa-2x"></i></button>
					</div>
					{/* <strong class="d-block fs-5 mt-4">Login Information</strong>
					<p class="d-block lh-150 py-4" dangerouslySetInnerHTML={{
						__html: product.login_information,
					  }}></p> */}
					<strong class="d-block fs-5 mt-4">Description</strong>
					<p class="d-block lh-150 py-4" dangerouslySetInnerHTML={{
						__html: product.description,
					  }}></p>
					<div class="d-flex gap-3">
						<a  onClick={()=>handleBuyNow(product)} class="btn btn-primary-ben rounded-0 px-sm-5 px-4 py-3 fw-bold my-1 fs-6 mb-4 mt-2">BUY NOW</a>
						<a onClick={handleBackBtn} class="btn btn-secondary-ben rounded-0 px-sm-5 px-4 py-3 fw-bold my-1 fs-6 text-uppercase mb-4 mt-2">Close It</a>
					</div>
					</div>
				</div>
				</div>
                </>:<>
                  <div className="loader-card text-center my-4 h-20">
                    <div
                      className="spinner-border text-warning"
                      role="status"
                    ></div>
                  </div>
                </>}
				
			</div>:<div class="p-4 detail-page">
				{product?<>
					<h3 class="fs-3 lh-150 fw-bold text-black text-start pt-3">{product.name}</h3>
				<div class="d-flex gap-3">
				<div class="d-flex gap-1 my-3 fs-12">
					<i class="fa fa-trophy text-primary-ben fs-12" title="Account Level"></i> <span>Buy Limit {product.minimum_buy_limit}</span>
				</div>
				<div class="d-flex gap-1 my-3 fs-12">
					<i class="fa fa-dollar text-primary-ben fs-12" title="Price"></i> <span>{product.price}</span>
				</div>
				</div>
				<div class="row">
				<div class="col-12 mt-4">
					<div class="post-detials">
					<img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${product.image}`} alt="Post Details" class="product-image" />

					<strong class="d-block fs-5 mt-4">Description</strong>
					<p class="d-block lh-150 py-4" dangerouslySetInnerHTML={{
						__html: product.description,
					  }}></p>
					<div class="d-flex gap-3">
						<a  onClick={()=>handleBuyNow(product)} class="btn btn-primary-ben rounded-0 px-sm-5 px-4 py-3 fw-bold my-1 fs-6 mb-4 mt-2">BUY NOW</a>
						<a onClick={handleBackBtn} class="btn btn-secondary-ben rounded-0 px-sm-5 px-4 py-3 fw-bold my-1 fs-6 text-uppercase mb-4 mt-2">Close It</a>
					</div>
					</div>
				</div>
				</div>
                </>:<>
                  <div className="loader-card text-center my-4 h-20">
                    <div
                      className="spinner-border text-warning"
                      role="status"
                    ></div>
                  </div>
                </>}
				
			</div>}
			
		</section>
		</div>
		</>
		
	);
}
export default Detail;