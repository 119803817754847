import React, { useEffect,useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../cartContext";
import logo from "../../website-assets/images/logo.svg";
import favicon from "../../website-assets/images/favicon.png";
import goldicon from "../../website-assets/images/goldicon.png";
import { ToastContainer, toast } from "react-toastify";
import { userActivity } from "../services/api";

const Head = (props) => {
  const { state } = useCart();
  const [isauth, setIsAuth] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [activity, setActivity] = useState("");
  const { dispatch } = useCart();
  
  const notify = () =>
    toast("Your Order Completed!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  
   
  
  const navigate = useNavigate();
  const navbarCollapseRef = useRef(null);

  useEffect(() => {
    const authentication = sessionStorage.getItem("token");
    if (authentication) {
      setIsAuth(true);
      setProfileImage(sessionStorage.getItem('profile_image'));
      getActivity();
    }
  }, []);

  const getActivity=async()=>{
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem("user_id");
      const response = await userActivity(token,userId);
      if(response.data > 0){
        setActivity(`You have ${response.data} pending orders`)
      }
     
  }
  
  const handleLogout = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    navigate("/login");
  };

  const closeMenu = () => {
    if (navbarCollapseRef.current?.classList.contains("show")) {
      navbarCollapseRef.current.classList.remove("show");
    }
  };
  return (
    <>
      <nav class="navbar navbar-expand-lg bg-dark-ben fixed-top z-4 main-menu py-lg-4">
        <div class="container justify-content-space-between">
          <Link class="navbar-brand" to="/">
            <img src={logo} alt="Ben GP Logo" class="img-fluid" />
          </Link>
          <div className="d-flex gap-3 justify-content-end align-items-center">
          <div className="dropdown notif navbar-toggler">
                    <a
                      href="#"
                      className="notification-bell fs-5 mt-2 d-block transition position-relative"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-shopping-cart"></i>
                      {state.items.length > 0 && (
                        <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                          {state.items.length}
                        </span>
                      )}
                    </a>
                    <ul className="dropdown-menu p-0 pb-3 end-0 start-auto rounded-0 bg-dark mt-3 cart-items"  onClick={(e) => e.stopPropagation()}>
                      <li className="p-4 text-white border-bottom">
                        <strong className="fs-16 fw-600 text-uppercase">Cart</strong>
                      </li>
                      {state.items.length === 0 ? (
                        <li>
                          <div className="dropdown-item px-4 py-3 fs-13 text-center text-light">
                            <i className="fa fa-circle fs-8 text-primary-ben"></i>
                            <span>
                              Your <strong>Cart</strong> is empty.
                            </span>
                          </div>
                        </li>
                      ) : (
                        state.items.map((item) => (
                          <li
                            key={item.id}
                            className="d-block p-3 pb-2 border-top-dark"
                          >
                            {item.product.product_type === 'product'?
                              <>
                              <div className="d-flex align-items-stretch gap-3 " >
                              <img
                                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.product.image}`}
                                alt={item.product.name}
                                className="img-fluid object-fit-cover rounded-2"
                                style={{
                                  width: "70px",
                                }}
                              />
                                <div className="w-100">
                                  
                                <span className="text-primary-ben fw-bold lh-150">{item.product.name}</span>
                                <div class="d-flex gap-2 justify-content-between align-items-center">
                                  <span className="text-light"><i class="fa-solid fa-dollar text-primary-ben fs-11"title="Price"></i> {item.product.price}</span>
                                  <div className="d-flex align-items-center">
                            
                                <span className="text-light px-4 py-3">
                                  ${(item.product.price * item.quantity).toFixed(2)}
                                </span>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => dispatch({ type: "REMOVE_ITEM", payload: item.id })}
                                  style={{ lineHeight: "1", padding: "0.25rem 0.5rem" }}
                                > 
                                <i class="fa fa-trash" ></i>
                              </button>
                            </div>
                                </div>
                                  </div>
                                  
                              
                                </div>
                                  <div className="d-flex align-items-center gap-2">
                                <button
                                  className="btn btn-primary-ben btn-sm"
                                  onClick={() =>
                                    dispatch({
                                      type: "ADD_ITEM",
                                      payload: { id: item.id, product: item.product, quantity: 1 },
                                    })
                                  }
                                >
                                  +
                                </button>
                                        {/* <span className="text-light px-2">{item.quantity}</span> */}
                                  <input
                                    type="number"
                                    className="form-control w-20 text-center no-spinner"
                                    value={item.quantity}
                                    min={item.product.rawProduct?.minimum_buy_limit || 1}
                                    onChange={(e) => {
                                      const newQuantity = parseInt(e.target.value, 10) || 0;
                                      if (newQuantity >= 1) {
                                        dispatch({
                                          type: "UPDATE_ITEM",
                                          payload: { ...item, quantity: newQuantity },
                                        });
                                      }
                                    }}
                                    onBlur={(e) => {
                                      const newQuantity = parseInt(e.target.value, 10) || 1;
                                      if (newQuantity < item.product.rawProduct?.minimum_buy_limit) {
                                        dispatch({
                                          type: "UPDATE_ITEM",
                                          payload: {
                                            ...item,
                                            quantity: item.product.rawProduct?.minimum_buy_limit || 1,
                                          },
                                        });
                                      }
                                    }}
                                  />

                                <button
                                  className="btn btn-primary-ben btn-sm"
                                  onClick={() => {
                                    const minLimit = item.product.rawProduct?.minimum_buy_limit || 1;
                                     if (
                                      item.product.product_type === "product" &&
                                      item.quantity <= minLimit
                                    ) {
                                      return;
                                    }
                                    if (item.quantity > 1) {
                                      dispatch({
                                        type: "UPDATE_ITEM",
                                        payload: { ...item, quantity: item.quantity - 1 },
                                      });
                                    } else {
                                      dispatch({ type: "REMOVE_ITEM", payload: item.id });
                                    }
                                  }}
                                >
                                  -
                                </button>
                              </div>
                              </> : item.product.product_type === 'account' ?
                            <>
                            <div className="d-flex align-items-stretch gap-3" >
                              <img
                                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.product.image}`}
                                alt={item.product.name}
                                className="img-fluid object-fit-cover rounded-2"
                                style={{
                                  width: "70px",
                                }}
                              />
                              <div className="w-100">
                                <span className="text-primary-ben fw-bold lh-150">{item.product.name}</span>
                                <div className="d-flex gap-2 justify-content-between align-items-center">
                                  <span className="text-light"><i class="fa fa-sign-in text-primary-ben fs-11" title="Account Level"></i> {item.product.rawProduct.level}</span>
                                  <div className="d-flex align-items-center">
                                    <span className="text-light px-4 py-3">
                                      ${(item.product.price * item.quantity).toFixed(2)}
                                    </span>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => dispatch({ type: "REMOVE_ITEM", payload: item.id })}
                                      style={{ lineHeight: "1", padding: "0.25rem 0.5rem" }}
                                    >
                                      <i class="fa fa-trash" ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </>:
                           <>
                                <div className="d-flex align-items-center gap-3">
                                    <img
                                        src={goldicon}
                                        alt={item.product.name}
                                        className="img-fluid object-fit-cover rounded-2"
                                        style={{
                                            width: "70px",
                                        }}
                                    />
                                    <div className="w-100">
                                        <span className="text-primary-ben fw-bold">
                                            {item.product.rawProduct.category === "1" ? "RS3" : "OSRS"} GOLD
                                        </span>
                                        <div className="d-flex gap-2 justify-content-between align-items-center">
                                            <span className="text-light">
                                                <i
                                                    className="fa-solid fa-list text-primary-ben fs-11"
                                                    title="Price"
                                                ></i>{" "}
                                                Price: {item.quantity}/M
                                            </span>
                                        
                                      </div>
                                      <div className="d-flex align-items-center">
                                                <button
                                                    className="btn btn-primary-ben btn-sm"
                                                    onClick={() =>
                                                        dispatch({
                                                            type: "ADD_ITEM",
                                                            payload: { id: item.id, product: item.product, quantity: 1 },
                                                        })
                                                    }
                                                >
                                                    +
                                                </button>
                                          {/* <span className="text-light px-2">{item.quantity}</span> */}
                                              <input
                                                  type="number"
                                                  className="form-control w-10 text-center no-spinner "
                                                  value={item.quantity}
                                                  min={1}
                                                  onChange={(e) => {
                                                    const newQuantity = parseInt(e.target.value, 10) || 0;
                                                    if (newQuantity >= 1) {
                                                      dispatch({
                                                        type: "UPDATE_ITEM",
                                                        payload: { ...item, quantity: newQuantity },
                                                      });
                                                    }
                                                  }}
                                                />
                                                <button
                                                    className="btn btn-primary-ben btn-sm"
                                                    onClick={() => {
                                                        if (item.quantity > 1) {
                                                            dispatch({
                                                                type: "UPDATE_ITEM",
                                                                payload: { ...item, quantity: item.quantity - 1 },
                                                            });
                                                        } else {
                                                            dispatch({ type: "REMOVE_ITEM", payload: item.id });
                                                        }
                                                    }}
                                                >
                                                    -
                                                </button>
                                                <span className="text-light px-4 py-3">
                                                    ${(item.product.price * item.quantity).toFixed(2)}
                                                </span>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        dispatch({ type: "REMOVE_ITEM", payload: item.id })
                                                    }
                                                    style={{ lineHeight: "1", padding: "0.25rem 0.5rem" }}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                    </div>
                                </div>
                            </>
                            }
                          </li>
                        ))
                      )}
                      {state.items.length > 0 && (
                        <li className="p-2">
                          <Link to="/checkout" className="btn btn-primary-ben w-100">
                            Go to Checkout
                          </Link>
                        </li>
                      )}
                    </ul>
            </div>
          <button
            class="navbar-toggler btn btn-primary-ben py-2 px-3 rounded-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa fa-bars"></i>
          </button>
          </div>
          <div class="collapse navbar-collapse" id="navbarSupportedContent"  ref={navbarCollapseRef}>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-5">
              <li class="nav-item">
                <Link
                  to="/"
                  class={`transition nav-link ${
                    props.component == "osrsGold" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={closeMenu} 
                >
                  OSRS Gold
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "rs3Gold" ? "active" : ""
                  }`}
                  to="/rs3-gold"
                  onClick={closeMenu} 
                >
                  RS3 Gold
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "osrsAccounts" ? "active" : ""
                  }`}
                  to="/all-osrs-accounts"
                  onClick={closeMenu} 
                >
                  OSRS Accounts
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "rs3Accounts" ? "active" : ""
                  }`}
                  to="/all-rs3-accounts"
                  onClick={closeMenu} 
                >
                  RS3 Accounts
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "osrsItems" ? "active" : ""
                  }`}
                  to="/all-osrs-items"
                  onClick={closeMenu} 
                >
                  OSRS Items
                </Link>
              </li>
              
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "rs3Items" ? "active" : ""
                  }`}
                  to="/all-rs3-items"
                  onClick={closeMenu} 
                >
                  RS3 Items
                </Link>
              </li>
            </ul>
            
            <div class="d-flex gap-4 align-items-center">
              {isauth ? (
                <>
                  <div className="dropdown notif d-none d-md-block">
                    <a
                      href="#"
                      className="notification-bell fs-5 transition position-relative"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-shopping-cart"></i>
                      {state.items.length > 0 && (
                        <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                          {state.items.length}
                        </span>
                      )}
                    </a>
                    <ul className="dropdown-menu p-0 pb-3 end-0 start-auto rounded-0 bg-dark mt-3 cart-items"  onClick={(e) => e.stopPropagation()}>
                      <li className="p-4 text-white border-bottom">
                        <strong className="fs-16 fw-600 text-uppercase">Cart</strong>
                      </li>
                      {state.items.length === 0 ? (
                        <li>
                          <div className="dropdown-item px-4 py-3 fs-13 text-center text-light">
                            <i className="fa fa-circle fs-8 text-primary-ben"></i>
                            <span>
                              Your <strong>Cart</strong> is empty.
                            </span>
                          </div>
                        </li>
                      ) : (
                        state.items.map((item) => (
                          <li
                            key={item.id}
                            className="d-block p-3 pb-2 border-top-dark"
                          >
                            {item.product.product_type === 'product'?
                              <>
                              <div className="d-flex align-items-stretch gap-3 " >
                              <img
                                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.product.image}`}
                                alt={item.product.name}
                                className="img-fluid object-fit-cover rounded-2"
                                style={{
                                  width: "70px",
                                }}
                              />
                                <div className="w-100">
                                  
                                <span className="text-primary-ben fw-bold lh-150">{item.product.name}</span>
                                <div class="d-flex gap-2 justify-content-between align-items-center">
                                  <span className="text-light"><i class="fa-solid fa-dollar text-primary-ben fs-11"title="Price"></i> {item.product.price}</span>
                                  <div className="d-flex align-items-center">
                            
                                <span className="text-light px-4 py-3">
                                  ${(item.product.price * item.quantity).toFixed(2)}
                                </span>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => dispatch({ type: "REMOVE_ITEM", payload: item.id })}
                                  style={{ lineHeight: "1", padding: "0.25rem 0.5rem" }}
                                > 
                                <i class="fa fa-trash" ></i>
                              </button>
                            </div>
                                </div>
                                  </div>
                                </div>
                                  <div className="d-flex align-items-center gap-2">
                                <button
                                  className="btn btn-primary-ben btn-sm"
                                  onClick={() =>
                                    dispatch({
                                      type: "ADD_ITEM",
                                      payload: { id: item.id, product: item.product, quantity: 1 },
                                    })
                                  }
                                >
                                  +
                                </button>
                                        {/* <span className="text-light px-2">{item.quantity}</span> */}
                                  <input
                                    type="number"
                                    className="form-control w-20 text-center no-spinner"
                                    value={item.quantity}
                                    min={item.product.rawProduct?.minimum_buy_limit || 1}
                                    onChange={(e) => {
                                      const newQuantity = parseInt(e.target.value, 10) || 0;
                                      if (newQuantity >= 1) {
                                        dispatch({
                                          type: "UPDATE_ITEM",
                                          payload: { ...item, quantity: newQuantity },
                                        });
                                      }
                                    }}
                                    onBlur={(e) => {
                                      const newQuantity = parseInt(e.target.value, 10) || 1;
                                      if (newQuantity < item.product.rawProduct?.minimum_buy_limit) {
                                        dispatch({
                                          type: "UPDATE_ITEM",
                                          payload: {
                                            ...item,
                                            quantity: item.product.rawProduct?.minimum_buy_limit || 1,
                                          },
                                        });
                                      }
                                    }}
                                  />

                                <button
                                  className="btn btn-primary-ben btn-sm"
                                  onClick={() => {
                                    const minLimit = item.product.rawProduct?.minimum_buy_limit || 1;
                                     if (
                                      item.product.product_type === "product" &&
                                      item.quantity <= minLimit
                                    ) {
                                      return;
                                    }
                                    if (item.quantity > 1) {
                                      dispatch({
                                        type: "UPDATE_ITEM",
                                        payload: { ...item, quantity: item.quantity - 1 },
                                      });
                                    } else {
                                      dispatch({ type: "REMOVE_ITEM", payload: item.id });
                                    }
                                  }}
                                >
                                  -
                                </button>
                              </div>
                              </> : item.product.product_type === 'account' ?
                            <>
                            <div className="d-flex align-items-stretch gap-3" >
                              <img
                                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.product.image}`}
                                alt={item.product.name}
                                className="img-fluid object-fit-cover rounded-2"
                                style={{
                                  width: "70px",
                                }}
                              />
                              <div className="w-100">
                                <span className="text-primary-ben fw-bold lh-150">{item.product.name}</span>
                                <div className="d-flex gap-2 justify-content-between align-items-center">
                                  <span className="text-light"><i class="fa fa-sign-in text-primary-ben fs-11" title="Account Level"></i> {item.product.rawProduct.level}</span>
                                  <div className="d-flex align-items-center">
                                    <span className="text-light px-4 py-3">
                                      ${(item.product.price * item.quantity).toFixed(2)}
                                    </span>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => dispatch({ type: "REMOVE_ITEM", payload: item.id })}
                                      style={{ lineHeight: "1", padding: "0.25rem 0.5rem" }}
                                    >
                                      <i class="fa fa-trash" ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </>:
                           <>
                                <div className="d-flex align-items-center gap-3">
                                    <img
                                        src={goldicon}
                                        alt={item.product.name}
                                        className="img-fluid object-fit-cover rounded-2"
                                        style={{
                                            width: "70px",
                                        }}
                                    />
                                    <div className="w-100">
                                        <span className="text-primary-ben fw-bold">
                                            {item.product.rawProduct.category === "1" ? "RS3" : "OSRS"} GOLD
                                        </span>
                                        <div className="d-flex gap-2 justify-content-between align-items-center">
                                            <span className="text-light">
                                                <i
                                                    className="fa-solid fa-list text-primary-ben fs-11"
                                                    title="Price"
                                                ></i>{" "}
                                                Price: {item.product.rawProduct.rate}/M
                                            </span> 
                                      </div>
                                      <div className="d-flex align-items-center">
                                                <button
                                                    className="btn btn-primary-ben btn-sm"
                                                    onClick={() =>
                                                        dispatch({
                                                            type: "ADD_ITEM",
                                                            payload: { id: item.id, product: item.product, quantity: 1 },
                                                        })
                                                    }
                                                >
                                                    +
                                                </button>
                                          {/* <span className="text-light px-2">{item.quantity}</span> */}
                                              <input
                                                  type="number"
                                                  className="form-control w-10 text-center no-spinner "
                                                  value={item.quantity}
                                                  min={1}
                                                  onChange={(e) => {
                                                    const newQuantity = parseInt(e.target.value, 10) || 0;
                                                    if (newQuantity >= 1) {
                                                      dispatch({
                                                        type: "UPDATE_ITEM",
                                                        payload: { ...item, quantity: newQuantity },
                                                      });
                                                    }
                                                  }}
                                                />
                                                <button
                                                    className="btn btn-primary-ben btn-sm"
                                                    onClick={() => {
                                                        if (item.quantity > 1) {
                                                            dispatch({
                                                                type: "UPDATE_ITEM",
                                                                payload: { ...item, quantity: item.quantity - 1 },
                                                            });
                                                        } else {
                                                            dispatch({ type: "REMOVE_ITEM", payload: item.id });
                                                        }
                                                    }}
                                                >
                                                    -
                                                </button>
                                                <span className="text-light px-4 py-3">
                                                  {console.log(item)}
                                                    ${(Number(item.product.price).toFixed(2))}
                                                </span>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        dispatch({ type: "REMOVE_ITEM", payload: item.id })
                                                    }
                                                    style={{ lineHeight: "1", padding: "0.25rem 0.5rem" }}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                    </div>
                                </div>
                            </>
                            }
                          </li>
                        ))
                      )}
                      {state.items.length > 0 && (
                        <li className="p-2">
                          <Link to="/checkout" className="btn btn-primary-ben w-100">
                            Go to Checkout
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>

                 
                  <div class="dropdown notif ms-2">
                    <a
                      href=""
                      class="profile-pic"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profileImage ? profileImage : favicon}
                        alt="Profile Pic"
                        width="40"
                        height="40"
                        class="img-fluid border-primary-ben"
                      />
                    </a>
                    <ul class="dropdown-menu bg-dark end-0 start-auto rounded-0">
                      {/* <li><a class="dropdown-item p-3 fs-13 transition" href=""><i class="me-2 fa fa-user text-primary-ben"></i> Profile</a></li>
							        <li><a class="dropdown-item p-3 fs-13 transition" href=""><i class="me-2 fa fa-key text-primary-ben"></i> Change Password</a></li> */}
                      <li>
                        <Link
                          class="dropdown-item p-3 fs-13 transition"
                          to="/dashboard"
                        >
                          <i class="me-2 fa fa-user text-primary-ben"></i>{" "}
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item p-3 fs-13 transition"
                          to="/profile"
                        >
                          <i class="me-2 fa fa-user text-primary-ben"></i>{" "}
                          Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          class="dropdown-item p-3 fs-13 transition"
                          href=""
                          onClick={handleLogout}
                        >
                          <i class="me-2 fa fa-sign-out text-primary-ben"></i>{" "}
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    name="btn"
                    class="btn btn-primary-ben  rounded-4 fw-bold p-3 text-uppercase"
                  >
                    Login
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Head;
