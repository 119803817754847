import React, { useEffect, useState } from "react";
import { osrsProducts, rs3Products } from "../services/api";
import { getSearchedProducts } from "../services/api";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { useCart } from "../cartContext";



const Items = (props) => {
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const canonicalUrl = `${window.location.href}`;
  const {state, dispatch } = useCart();
  useEffect(() => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('#navbarSupportedContent');

    if (navbarToggler && navbarCollapse.classList.contains('show')) {
      navbarToggler.click(); 
    }
    fetchdata();
  }, [props.component]);

  const fetchdata = async () => {
    setLoader(true);
    if (props.component === "OSRS") {
      props.setMetaDescription('OSRS Accounts | Buy OSRS Accounts | OSRS Accounts | Sell OSRS Accounts | Sell OSRS Accounts | OSRS Staff | BenGP');
      props.setMetaTitle('OSRS Items | OSRS Accounts | OSRS Gold & Accounts');
      const response = await osrsProducts();
      setProducts(response.data);
    } else {
      props.setMetaDescription('RS3 Gold | Buy RS3 GP | Sell RS3 Gold | Sell RS3 GP | RS3 Gold | RS3 Accounts | RS3 Items Rs3 accounts for sale | Cheap RS3 Gold | Buy Runescape Accounts RS3 | BenGP');
      props.setMetaTitle('RS3 Gold | Buy RS3 gp | Sell RS3 Gold | Sell RS3 GP');
      const response = await rs3Products();
      setProducts(response.data);
    }
    setLoader(false);
  };
  const handleSearchChange = async (e) => {
    let key;
    if (e.target.value) {
      key = e.target.value;
    }
    else {
      key = '0';
    }
    const response = await getSearchedProducts(key);
    setProducts(response.data);
  }
   const normalizeProduct = (product) => {
    
      return {
        id: product.id,
        name: product.title || product.name,
        price: product.cost || product.price,
        type: product.type,
        image: product.image,
        product_type: 'product',
        minimum_buy_limit: product.minimum_buy_limit || 1,
        rawProduct: product,
      };
  };  

  const handleAddToCart = (productobj) => {
    const product = normalizeProduct(productobj);
    const initialQuantity = product.minimum_buy_limit || 1;
      dispatch({
        type: "ADD_ITEM",
        payload: { id: product.id, product, quantity: initialQuantity },
      });
  };
  
  const handleRemoveFromCart = (productId) => {
    dispatch({ type: "REMOVE_ITEM", payload: productId });
  };
  
 const handleBuyNow = (product) => {
  sessionStorage.setItem("selectedAccount", JSON.stringify(product));
  sessionStorage.setItem("type", "0");
  const isAuthenticated = sessionStorage.getItem("token");
  if (!isAuthenticated) {
    sessionStorage.setItem("redirectPath", "/checkout");
    navigate("/login");
    return;
  }else{
    navigate("/checkout")
  }
};
  return (
    <div className="login-register  mt-5">
       <Helmet>
        <link rel="canonical" href={`${canonicalUrl}`} />
      </Helmet>
      <em class="over position-fixed bg-black opacity-85 bottom-0 top-0 end-0 start-0"></em>
      <div class="container position-relative z-1 mt-4">
        <div class="row mt-4">
          <div class="col-12 mt-4">
            <div class="row mt-4">
              <div class="col-sm-12 my-4">
                <div class="input-group mb-4 mt-2 bg-light-ben position-relative w-280">
                  <input
                    type="text"
                    class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                  />
                  <a href="javascript:;" class="btn pt-1 me-1 border-0">
                    <i class="fa fa-search text-light-ben mt-3"></i>
                  </a>
                </div>
              </div>
              <div class="col-6">
                <h4 class="h5 fw-bold mb-0 pb-4 text-white">
                  {props.component} Items
                </h4>
              </div>
              <div class="col-6 mb-3">
                <p class="text-light-ben float-end fs-12">{products.length} Items</p>
              </div>
              {loader ? (
                <div className="loader-card text-center my-4 h-20">
                  <div
                    className="spinner-border text-warning"
                    role="status"
                  ></div>
                </div>
              ) : (
                  products.map((product) => {
                        const isInCart = state.items.some(
                            (item) => item.id === product.id && item.product.product_type === "product"
                        );                    
                      return (<div class="col-lg-3 col-md-6 mb-4">
                      <div class="items-box product-box p-3 position-relative transition">
                        <a
                          href="javascript:;"
                          class="img-holder text-center position-relative d-block text-white"
                        >
                          {sessionStorage.getItem('token') && 
                            (!isInCart ? (
                            <button
                              onClick={() => handleAddToCart(product)}
                              className="bg-danger fs-15 p-2 px-2 d-inline-block position-absolute start-0 top-0"
                              style={{
                                borderRadius:"50%",
                                zIndex:"999",
                                border:"none",
                                color:"white"
                              }}
                            >
                            <i class="fa fa-shopping-cart"></i>
                            </button>
                            ) : (
                              <button
                                onClick={() => handleRemoveFromCart(product.id)}
                                className="bg-danger fs-15 p-2 px-2 d-inline-block position-absolute start-0 top-0"
                                style={{
                                  borderRadius:"10px",
                                zIndex:"999",
                                border:"none",
                                color:"white"
                              }}
                              >
                                Remove from Cart
                              </button>
                            ))}
                          {/* <span class="bg-danger fs-12 py-1 px-2 d-inline-block position-absolute start-0 top-0">
                          </span> */}
                          <img
                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${product.image}`}
                            alt="Gloves"
                            class="img-fluid transition mb-3"
                            style={{ maxHeight: "200px" }}
                          />
                        </a>
                        <a
                          href="javascript:;"
                          class="text-primary-ben fw-bold fs-5"
                        >
                          {product.name}
                        </a>
                        <span class="d-block py-3 text-white">
                          $ {product.price} / unit
                        </span>
                        <div class="d-flex justify-content-between align-items-center">
                          {/* <Link
                                to={`/detail/item/${product.id}`}
                                class="btn btn-primary-ben rounded-0 px-3 my-1 fs-12 see-detail "
                              >
                                SEE DETAIL
                              </Link> */}
                          <a
                            onClick={() => handleBuyNow(product)}
                            class="btn btn-primary-ben rounded-0 px-3 my-2 fs-13"
                          >
                            BUY NOW
                          </a>
                            <span class="  fs-12 my-2 text-light">
                              <i class="fa fa-clock me-1"></i> 20 mins
                            </span>
                        </div>
                      </div>
                    </div>)
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Items;
