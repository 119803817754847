import React from "react";
import { useEffect } from "react";
import {Intercom} from "@intercom/messenger-js-sdk";
import { show } from "@intercom/messenger-js-sdk";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { createIntercomContact } from "../services/api";
import { createIntercomConversation } from "../services/api";
import { updateOrderPayment } from "../services/api";
import { useCart } from "../cartContext";


const Thankyou=()=>{
	const navigate= useNavigate();
	const { state,dispatch } = useCart();
	useEffect(()=>{
		const orderGenerated = sessionStorage.getItem("message");
		if(!orderGenerated){
			navigate("/");
		}
		const queryParams = new URLSearchParams(window.location.search);
		if (Array.from(queryParams).length > 0) {
		  const orderId = queryParams.get("order_id");
		  const track = queryParams.get("tracker");
			updateOrderStatus(orderId,track);
		}
		sendorderMessage();
	},[]);

	const updateOrderStatus= async(orderId,track)=>{
		await updateOrderPayment(orderId,track);
	} 

	const sendorderMessage =async () =>{
	const message = sessionStorage.getItem("message");
	if(message){
		const message = JSON.parse(sessionStorage.getItem("message"));
		if(message){
		const token = sessionStorage.getItem('token');
		const formData = new FormData();
		formData.append("email",sessionStorage.getItem("user_email"));
		const resp = await createIntercomContact(token, formData);
		if(resp && resp.data.contact_id ){
			const form = new FormData();
			form.append("contactId",resp.data.contact_id);
			form.append("message",message);
			const response = await createIntercomConversation(token,form);
		}
		
		Intercom({
			app_id: 'wusw8k2t',
			email: sessionStorage.getItem("user_email"), 
			created_at: Math.floor(Date.now() / 1000) 
		});
		show();
		dispatch({ type: "RESET_CART" });
		sessionStorage.removeItem("message");
		sessionStorage.setItem("orderMessageSent", "true");
		}
	}
	
	}

	return (
		<>
			<div class="login-register container-fluid vh-100 position-relative z-1">
				<div class="row align-items-center h-100">
					<div class="col-lg-2 col-md-1"></div>
					<div class="col-lg-8 col-md-10">
					<section class=" thankyou-card bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
						<div class="row">
						<div class="col-md-12 ps-md-6 text-center pt-4">
						<img width="90" height="90" src="https://img.icons8.com/sf-black-filled/64/FAB005/checked-2.png" alt="checked-2"/><strong class="thankyou-text d-block text-light-ben pt-5 lh-150">Thankyou for Choosing BenGP</strong> <br></br><span className="thankyou-subtext">Our agent will contact you soon ! </span><br></br>
						<Link to="/" name="btn" class=" mt-4 btn btn-primary-ben w-20 rounded-0 fw-bold p-3 text-uppercase">Back to Home</Link>
						</div>
						</div>
					</section>
					</div>
					<div class="col-lg-2 col-md-1"></div>
				</div>
			</div>
		</>
	);
}
export default Thankyou;